import { ref } from '@vue/composition-api'

export default function usePolicies() {
  const loading = ref(false)
  const searchQuery = ref('')
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')
  const expandPolicyRow = ref([])

  const policyHeaders = [
    { text: 'INVESTMENT CLASS', value: 'investmentClass' },
    { text: 'PRECLEARING', value: 'preclearing' },
    { text: 'REPORTING', value: 'reporting' },
    { text: 'APPROVAL PERIOD', value: 'approvalPeriod' },
    { text: 'HOLDING PERIOD', value: 'holdingPeriod' },
  ]
  const policiesList = [
    {
      investmentClass: 'Listed Securities',
      preclearing: 'Yes',
      reporting: 'Yes',
      approvalPeriod: '5',
      holdingPeriod: '30',
    },
    {
      investmentClass: 'Exchange Traded Derivatives',
      preclearing: 'Yes',
      reporting: 'Yes',
      approvalPeriod: '12',
      holdingPeriod: '30',
    },
  ]
  const policySelectOptions = ref(['Yes', 'No', 'Prohibited'])
  const checkExpandedRow = currentRow => {
    console.log(currentRow)
    console.log(expandPolicyRow)
    return currentRow.investmentClass === expandPolicyRow.value[0]?.investmentClass
  }

  return {
    loading,
    searchQuery,
    companies,
    selectedCompany,
    policyHeaders,
    policiesList,
    expandPolicyRow,
    checkExpandedRow,
    policySelectOptions,
  }
}
