<template>
  <div>
    <ac-module-heading> Policies </ac-module-heading>
    <v-card id="policies-list" class="card-wrapper">
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-select
            label="Company"
            v-model="selectedCompany"
            :items="companies"
            class="company-select me-3"
          />
          <ac-button title="Download CSV" class="me-3" :icon="icons.mdiDownload" />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
      </v-card-text>
      <v-data-table
        :headers="policyHeaders"
        :items="policiesList"
        item-key="investmentClass"
        :loading="loading"
        class="text-no-wrap"
        :expanded.sync="expandPolicyRow"
        @click:row="checkExpandedRow"
        show-expand
        single-expand
      >
        <template #[`item.investmentClass`]="{ item }">
          <span class="font-weight-medium">{{ item.investmentClass }}</span>
        </template>

        <template #[`item.preclearing`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.preclearing }}</span>
          <ac-select
            v-else
            :items="policySelectOptions"
            class="selected-row-input"
            label="Yes"
            full-width
          >
          </ac-select>
        </template>

        <template #[`item.reporting`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.reporting }}</span>
          <ac-select
            v-else
            :items="policySelectOptions"
            class="selected-row-input"
            label="Yes"
            full-width
          >
          </ac-select>
        </template>

        <template #[`item.approvalPeriod`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.approvalPeriod }}</span>
          <ac-input v-else class="selected-row-input" type="number"> </ac-input>
        </template>

        <template #[`item.holdingPeriod`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.holdingPeriod }}</span>
          <ac-input v-else class="selected-row-input" type="number"> </ac-input>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card class="my-2">
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="align-start">
                      <div class="d-flex flex-column">
                        <div class="text-h6">Approver Designation</div>
                        <v-switch
                          v-model="switch1"
                          label="Initial Approver only.If this is unchecked,the investment class will require
                        2 layers of approvers"
                          class="mt-0"
                          hide-details
                          @click.stop
                        ></v-switch>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider class="mb-2"></v-divider>
                      <div>
                        <v-row class="header">
                          <v-col cols="2" class="font-weight-bold"> Staff Name </v-col>
                          <v-col class="font-weight-bold"> Initial Approvers</v-col>
                          <v-col cols="3" class="font-weight-bold"> Subsequent Approvers</v-col>
                        </v-row>
                        <v-row class="record" v-for="n in 5" :key="n" align="baseline">
                          <v-col class="py-0" cols="2"> Complaince Test {{ n }} </v-col>
                          <v-col class="d-flex flex-wrap py-0">
                            <div class="mr-4">
                              <v-switch
                                v-model="switch2"
                                label="Requester can choose"
                                class="mt-0"
                                hide-details
                                @click.stop
                              ></v-switch>
                            </div>
                            <div class="d-flex">
                              <v-select label="Primary" class="company-select" outlined dense>
                              </v-select>
                              <v-select
                                label="Secondary"
                                class="ml-4 company-select"
                                outlined
                                dense
                              >
                              </v-select>
                            </div>
                          </v-col>
                          <v-col class="py-0" cols="2"> Set to not required</v-col>
                          <v-col class="py-0" cols="1">
                            <ac-icon-btn
                              :text="`Copy all approver settings to all investment classes`"
                              :icon="icons.mdiContentCopy"
                              color="success"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                <ac-button title="Save" color="success" small />
                <ac-button
                  title="Cancel"
                  color="error"
                  outlined
                  small
                  @click="expandPolicyRow = []"
                />
              </v-card-actions>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import { onUnmounted } from '@vue/composition-api'
import { mdiContentCopy, mdiDownload } from '@mdi/js'
import usePolicies from './composition-apis/policies.js'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      searchQuery,
      companies,
      selectedCompany,
      loading,
      policyHeaders,
      policiesList,
      expandPolicyRow,
      checkExpandedRow,
      policySelectOptions,
    } = usePolicies()

    return {
      searchQuery,
      companies,
      selectedCompany,
      loading,
      policyHeaders,
      policiesList,
      expandPolicyRow,
      checkExpandedRow,
      policySelectOptions,
      icons: { mdiContentCopy, mdiDownload },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
  .selected-row-input {
    max-width: 150px;
  }
}
</style>
